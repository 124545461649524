import React, { useState } from "react";
import {Layout, Col, Row, Button } from "antd";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { UPLOADS_URL } from "../../../config/constants/api";
import { mypetDate } from "../../../components/Data/data";
import profilepic from "../../../assets/profilepic.png";
import { extractDate } from "../../../config/constants";
import { FaArrowLeftLong } from "react-icons/fa6";

const MyPets = () => {
  const profileDetails = useSelector((state) => state.user.profileDetails);

  const navigate = useNavigate();
  const { id } = useParams();
  const [mypet, setMypet] = useState(mypetDate.find((item) => item.id == id));

  return (
    <Layout
    className=""
    style={{ backgroundColor: "#fff" ,padding:"50px 0" }}
  >
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">My pet Profile</h3>
              </div>
            </Col>
          </Row>

          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <div className="about-us-section">
                      <div className="bg-parent">
                        <Row gutter={[16, 16]} align={"middle"}>
                          <Col md={24} lg={24} xl={24}>
                            <div className="pet-img-detail">
                              <div>{mypet.pic}</div>
                              <div>
                                <div>
                                  <h5 className="text-16">Name</h5>
                                  <p className="web-p">{mypet.name}</p>
                                </div>
                                <div>
                                  <h5 className="text-16">Gender</h5>
                                  <p className="web-p">{mypet.gender}</p>
                                </div>
                                <div>
                                  <h5 className="text-16">Date Of Birth</h5>
                                  <p className="web-p">{mypet.dateOfBirth}</p>
                                </div>
                              </div>
                            </div>

                            <div className="pet-details-box">
                              <h4>Your Pet's Breed</h4>
                              <p>{mypet.breed}</p>
                              <h4>Your Pet's Medical Condition</h4>
                              <p>{mypet.medicalCondition}</p>
                              <h4>Your Pet's Dietary Preferences</h4>
                              <p>{mypet.dietaryPreferences}</p>
                              <h4>Your Pet's Special Instructions</h4>
                              <p>{mypet.instructions}</p>
                            </div>
                            <Button
                                type="button"
                                htmlType=""
                                className="web-btn"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => navigate("/editPetProfile")}
                              >
                                Edit Profile
                              </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </Layout>
  );
};

export default MyPets;

import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Card,
  Checkbox,
  Rate,
  Typography,
  Button,
  Input,
  Divider,
  Form,
  Select,
  DatePicker,
  TimePicker,
  Space,
  Radio,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  FaArrowLeftLong,
  FaLocationDot,
  FaParachuteBox,
} from "react-icons/fa6";
import { serviceData, reviews } from "../../../components/Data/data";
import { MdSunny } from "react-icons/md";
import Slider from "react-slick";
import { CalendarFilled, ClockCircleFilled } from "@ant-design/icons";
// const { Meta } = Card;
const { Title } = Typography;

const JumpDetails = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const location = useLocation();
  const id = location?.state?.id;
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const navigate = useNavigate();

  const [service, setService] = useState(
    serviceData.find((item) => item.id == id)
  );

  console.log(id);
  const sliderSettings = {
    arrows: false,
    // other settings...
  };
  const sliderSettings2 = {
    // arrows: false,
    // style: {
    //   margin: "20px",
    // },
  };
  //   const onChange = (date, dateString) => {
  //     console.log(date, dateString);
  //   };
  const PickerWithType = ({ type, onChange }) => {
    if (type === "time") return <TimePicker onChange={onChange} />;
    if (type === "date") return <DatePicker onChange={onChange} />;
    return <DatePicker picker={type} onChange={onChange} />;
  };
  const { Option } = Select;
  const [type, setType] = useState("time");
  const { TextArea } = Input;
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Checkout</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={23}>
              <Card className="details-card">
                <Row align="" gutter={16}>
                  <Col lg={10}>
                    <div
                      className="one-product"
                      style={{ paddingRight: "30px" }}
                    >
                      <div className="search-img">
                        <div>
                          <span>{service.pic}</span>
                        </div>
                        <div className="rating-box">
                          <Rate
                            allowHalf
                            defaultValue={service.rating}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={14}>
                    <div className="room-details">
                      <div>
                        <h4 className="text-26">{service.title}</h4>
                        <div className="jump-inner-detail">
                          <div>
                            <p>
                              <ClockCircleFilled /> {service.time}
                            </p>
                          </div>
                          <div>
                            <p>
                              <CalendarFilled /> {service.date}
                            </p>
                          </div>
                          <div>
                            <p>
                              <FaLocationDot /> {service.loctaion}
                            </p>
                          </div>
                        </div>
                        <Divider dashed />
                        <h6 className="booking-card-price">${service.price}</h6>
                        <h5 className="heading-18">Additional Comments</h5>
                        <p className="web-p">{service.additionalDetails}</p>
                      </div>
                    </div>

                    <div className="search-img-box-right">
                      <h5 className="heading-18">
                        Would you like to add some tip amount for our employee?
                      </h5>
                      <Radio.Group
                        className="yellow-radio"
                        onChange={onChange}
                        value={value}
                      >
                        <Radio value={1}>$ 10.00</Radio>
                        <Radio value={2}>$ 20.00</Radio>
                        <Radio value={3}>$ 30.00</Radio>
                        <Radio value={4}>$ 40.00</Radio>
                        <Radio value={5}>Choose Custom Tip</Radio>
                      </Radio.Group>
                    </div>

                    <div class="checkout-pay-details">
                      <Input className="web-input" placeholder="Enter Amount" />
                      <div class="price-summary-detail">
                        <p>Total Hour</p>
                        <p>02</p>
                      </div>
                      <div class="price-summary-detail">
                        <p>Tip Amount</p>
                        <p>$20.00</p>
                      </div>
                      <div class="price-summary-detail">
                        <p>Total Payable</p>
                        <p>$20.00</p>
                      </div>
                    </div>

                    <div>
                      <Button
                        type=""
                        className="web-btn"
                        onClick={() => navigate("/jumpCheckout")}
                      >
                        Proceed to pAy
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default JumpDetails;

import { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Image, Badge, Typography, Divider } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong, FaLocationDot } from "react-icons/fa6";
import { bookingDate } from "../../../components/Data/data";
import { CalendarFilled, ClockCircleFilled } from "@ant-design/icons";
import petTraining from "../../../assets/petTraining.png";
import petWalking from "../../../assets/petWalking.png";
import petGrooming from "../../../assets/petGrooming.png";
import vetConsultation from "../../../assets/vetConsultation.png";
const { Meta } = Card;
const { Text } = Typography;

const AsNavFor = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  const [booking, setBooking] = useState(
    bookingDate.find((item) => item.id == id)
  );
  const sliderSettings = {
    arrows: false,
    // other settings...
  };
  const sliderSettings2 = {
    // arrows: false,
    // style: {
    //   margin: "20px",
    // },
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Booking Details</h3>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col lg={24}>
              <Card className="details-card">
                <Row align="middle" gutter={[32, 32]}>
                  <Col lg={10}>
                    <div className="one-product">
                      <Badge.Ribbon
                        text={booking.statusText}
                        color={
                          booking.statusText === "Completed"
                            ? "#00B31D"
                            : booking.statusText === "Upcoming"
                            ? "#DD9F00"
                            : booking.statusText === "Ongoing"
                            ? "#2D308B"
                            : "red"
                        }
                        placement="start"
                      >
                        <div>
                          <span>{booking.pic}</span>
                        </div>
                      </Badge.Ribbon>
                    </div>
                  </Col>
                  <Col lg={14}>
                    <h2 className="booking-details-name">{booking.title}</h2>
                    <div className="jump-inner-detail">
                      <div>
                        <p>
                          <ClockCircleFilled /> {booking.time}
                        </p>
                      </div>
                      <div>
                        <p>
                          <CalendarFilled /> {booking.date}
                        </p>
                      </div>

                      <div>
                        <p>
                          <FaLocationDot /> {booking.loctaion}
                        </p>
                      </div>
                    </div>
                    <div
                      className="search-result-detail-btnbox"
                      style={{ textAlign: "start" }}
                    >
                      <h6 className="per-day-price">${booking.price}</h6>
                    </div>
                    <Divider dashed />
                    <div className="search-img-box-right additional-details">
                      <h5 className="heading-18">Service Details</h5>
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={petTraining}
                        style={{ padding: "5px" }}
                      />
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={petWalking}
                        style={{ padding: "5px" }}
                      />
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={petGrooming}
                        style={{ padding: "5px" }}
                      />
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={vetConsultation}
                        style={{ padding: "5px" }}
                      />
                    </div>
                    <div className="search-img-box-right additional-details">
                      <h5 className="heading-18">My Comments</h5>
                      <p className="web-p">{booking.comments}</p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AsNavFor;

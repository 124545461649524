export const BASE_URL = "https://lm.demos2clients.com/api"
export const UPLOADS_URL = "https://lm.demos2clients.com/uploads"

export const UPLOAD_URL = 'https://example.com/upload';
export const SITE_NAME = 'My Website';
export const countries = ['USA', 'Canada', 'UK'];

// export const BASE_URL = "http://localhost:5001/api"
// export const UPLOADS_URL = "http://localhost:5001/uploads"


export const AUTH = {
    login: "/auth/signin"
}


export const USERS = {
    get: "/auth/signin"
}






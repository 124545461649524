import { Col, Row, Typography, Input, Form, Button } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import swal from "sweetalert";
// const { Meta } = Card;
const { Title } = Typography;
const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};
const UpcomingEvents = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const onFinish = (values) => {
    // Handle form submission
    console.log("Received values:", values);
  };
  const { TextArea } = Input;
  const handleChange = () => {
    swal("system Alert", "Your Message has been Send", "success");
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Get In Touch</h3>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        <Col lg={14}>
          <div className="details-card">
            <Form
              className="row g-3"
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row style={{ width: "100%" }} gutter={[16, 16]}>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="Full Name"
                    name="FullName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Full Name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter First Name"
                      className="web-input"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Last Name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Last Name"
                      className="web-input"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Email!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Email address"
                      className="web-input"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="Subject"
                    name="Subject"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Subject!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Subject"
                      className="web-input"
                    />
                  </Form.Item>
                </Col>
                <Col lg={24} md={24} xs={24}>
                  <Form.Item
                    label="Message"
                    name="Message"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Message!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Write your message.."
                      maxLength={6}
                      className="web-textarea"
                    />
                  </Form.Item>
                </Col>
                <Col lg={24} md={24} xs={24}>
                <div className="" style={{ textAlign: "center" }}>
                  <Button
                    type=""
                    htmlType="submit"
                    className="btn web-btn px-5"
                    onClick={handleChange}
                  >
                    Submit Now
                  </Button>
                </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingEvents;
